
.FooterWrapper
    width: 100%
    text-align: center
    margin-bottom: 1rem
    margin-top: .5rem

    hr
        border-color: #38393b
        max-width: 425px
    
        border-top: 1px solid rgba(0, 0, 0, .1)

    .FooterLinks 
        color: #bdc0c2
        display: flex
        align-items: center
        justify-content: center
        
        span
            margin-bottom: .35rem

        a 
            color: #4b99d1
            margin-left: .25rem
            margin-right: .25rem
            text-decoration: none
    
    .FooterCredits
        margin-top: .5rem
        display: flex
        justify-content: center
        gap: .2rem
        font-size: 11px
        color: #83898e