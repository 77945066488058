
.TransformModalWrapper
    position: fixed 
    left: 0
    top: 0
    background: #1c1c1c
    width: 100%
    height: 80%
    transition: .2s all ease
    transform: translateY(-100%)
    z-index: 100

    &.Active 
        transform: translateY(0%)

.Blurer
    position: fixed 
    left: 0
    top: 0
    background: rgba(0,0,0, .5)
    backdrop-filter: blur(4px)
    width: 100%
    height: 100%
    z-index: 99 
    transition: .2s all ease 
    opacity: 0
    visibility: hidden

    &.Active
        visibility: visible
        opacity: 1