@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,1000,2000,3000,4000,5000,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

svg
    display: block
* 
    font-family: system-ui, -apple-system, BlinkMacSyste
    box-sizing: border-box
    
    ::-webkit-scrollbar 
        width: 5px
    

    /* Track */
    ::-webkit-scrollbar-track 
    

    /* Handle */
    ::-webkit-scrollbar-thumb 
        background: #4b99d1
        border-radius: 100px
    

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover 
        background: #4b99d1

img
    display: block!important
    

body, html
    margin: 0
    background: #1c1c1c
    color: #bdc0c2


input 
    background: #262626
    border: none 
    outline: none
    color: #bdc0c2!important
    font-size: 16px
    line-height: inherit
    display: inline-block
    padding-inline-end: 12px
    padding-inline-start: 12px
    padding-left: 12px
    padding-right: 12px
    padding-bottom: 6px 
    padding-top: 6px
    padding-block-end: 6px
    padding-block-start: 6px     

button
    cursor: pointer
    background: #262626
    border: none 
    outline: none
    color: #bdc0c2
    font-size: 16px
    line-height: inherit
    display: inline-block
    padding-inline-end: 12px
    padding-inline-start: 12px
    padding-left: 12px
    padding-right: 12px
    padding-bottom: 6px 
    padding-top: 6px
    padding-block-end: 6px
    padding-block-start: 6px 
    transition: .2s all ease    

    &:hover 
        background: #323232

.w-md-editor-toolbar
    background: transparent
    border-radius: 0
    --md-editor-box-shadow-color: #30363d


.w-md-editor
    --md-editor-box-shadow-color: none
    color: #bdc0c2!important

svg 
    fill: #bdc0c2!important 
    color: #bdc0c2!important
