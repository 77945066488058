
.TextTabWrapper
    height: 100%

    textarea
        width: 100%
        height: 100%
        margin: 0
        padding: 0
        background: transparent
        outline: none
        border: none
        padding: 1.5rem
        color: #bdc0c2
        font-size: 19px
        resize: none