.LoadingWrapper
    height: 100vh
    width: 100vw 
    display: grid
    justify-items: center
    align-content: center
    gap: 1rem

.BtnBox
    display: flex 
    gap: .5rem

.ErrorContainer
    display: grid
    justify-content: center
    justify-items: center

.VisitorParamBtn
    margin-top: 1rem

    svg 
        width: 19px 
        height: 19px
        display: block

    &:hover
        opacity: .7

.BtnInner
    display: flex
    gap: .5rem

    button 
        margin-top: .5rem

.StatsPageContainer 
    background: #262626
    display: flex
    flex-direction: column
    height: 100vh
    align-items: center

    .Controlls
        display: flex
        gap: .5rem



    input
        background: #1c1c1c
    button
        background: #1c1c1c
        &:hover
            background: #323232
            opacity: 1

    .VisitorsList 
        flex: 1
        overflow-y: scroll
        display: grid
        justify-items: center
        align-items: center
        align-content: flex-start


    .VisitorsList .VisitorWrapper:nth-child(odd) 
        background: #1c1c1c

        .Spacer
            background: #262626

    .AuthWrapper
        flex: 1
        display: grid
        gap: .5rem
        justify-items: center
        align-content: center
        .InputOutline
            color: #bb8483

            div
                margin-bottom: .5rem

            input 


            &.Active 
                input
                    border-left: 4px solid #9b5756

.VisitorsList .VisitorWrapper:nth-child(odd) 
    background: #1c1c1c

    .Spacer
        background: #262626
        

.VisitorsList 
    padding-right: 5px
    display: flex
    flex-direction: column
    gap: 0
    flex: 1
    overflow-y: auto
    align-items: center

    &.Extend 
        align-content: center

    .NoVisitsContainer
        display: flex
        height: 100%
        align-items: center
        font-size: 20px


    .VisitorWrapper
        display: flex
        gap: 1rem
        padding: 1rem 2rem
        align-items: center

        .Spacer
            background: #1c1c1c
            width: 2px 
            height: 30px

.RootPageContainer
    display: flex 
    flex-direction: column
    min-height: 100vh
    max-height: 100vh

    .VisitorsModal 
        background: #262626
        height: 100%
        margin: auto
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        transition: .2s all ease
        z-index: 1

        .VisitorsModalContent
            display: flex
            flex-direction: column
            gap: .5rem 
            justify-items: center
            height: 100%
            overflow: hidden
            align-items: center
            justify-content: center   

            .InputOutline
                color: #bb8483

                div
                    margin-bottom: .5rem

                input 


                &.Active 
                    input
                        border-left: 4px solid #9b5756



            input
                background: #1c1c1c
                
            button 
                background: #1c1c1c
                &:hover
                    background: #323232
                



    .EditCodeContainer 
        
        padding: 1rem .5rem
        padding-top: 1.5rem
        width: 1140px !important
        max-width: 100% !important
        margin: auto 
        color: #699269

        span 
            font-family: consolas, sans-serif


    .ToolbarFooter 
        width: 1140px !important
        max-width: 100% !important
        margin: auto
        padding-right: .5rem
        padding-left: .5rem
        display: flex
        align-items: flex-start
        justify-content: space-between

        .StartBlock 
            display: flex
            gap: .5rem

            .SaveBtn 
                color: #9bcab9

        .EndBlock
            display: grid
            justify-items: end
            color: #83898e

            div 
                display: flex 
                gap: .5rem

        &.Edit 
            flex-direction: column
            gap: .5rem

            .EditInps 
                width: 100%
                display: flex
                align-items: flex-end
                gap: .5rem

                .InputOutline
                    color: #bb8483

                    div
                        margin-bottom: .5rem

                    input 


                    &.Active 
                        input
                            border-left: 4px solid #9b5756

                input 
                    width: 100%
            .EditBtns
                width: 1140px !important
                max-width: 100% !important

                .DeleteBtn 
                    color: #e0aeb4

                display: flex
                justify-content: space-between

        .FooterInps 
            display: flex
            gap: .5rem



    
@media screen and (max-width: 575px) 
    .ToolbarFooter
        flex-direction: column-reverse
        gap: .5rem
        align-items: flex-start

        .FooterInps 
            width: calc(100% - .5rem)
            display: grid!important
            grid-template-columns: 50% 50%
